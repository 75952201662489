<template>
    <div
        style="width: 100%"
        class="kits-leave-absence"
    >
        <div class="title">
            <img
                class="transfer-icon"
                :src="transferIcon"
                alt="调休图标"
            />
            <span class="label">请假/调休套件</span>
        </div>
        <el-form-item
            label="请假类型"
            required
        >
            <el-select
                v-model="ruleForm.region"
                placeholder="请选择"
                style="width: 100%"
            >
                <el-option
                    v-for="item in leaveList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
        </el-form-item>
        <div>
            <el-form-item
                v-if="form.show1"
                :label="form.label1"
                required
            >
                <el-date-picker
                    v-model="ruleForm.value1"
                    type="date"
                    style="width: 100%"
                    placeholder="选择日期"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item
                v-if="form.show2"
                :label="form.label2"
                required
            >
                <el-date-picker
                    v-model="ruleForm.value2"
                    type="date"
                    style="width: 100%"
                    placeholder="选择日期"
                >
                </el-date-picker>
            </el-form-item>
        </div>
        <el-form-item
            label="时长"
            prop="desc"
        >
            <el-input
                style="width: 100%"
                v-model="ruleForm.desc"
            ></el-input>
        </el-form-item>
        <el-form-item
            label="请假事由"
            prop="reasonLeave"
            :required="reasonLeaveRequiredType"
        >
            <el-input
                style="width: 100%"
                type="textarea"
                :rows="2"
                v-model="ruleForm.reasonLeave"
            ></el-input>
        </el-form-item>
        <el-form-item
            v-if="openInSchool == '1'"
            label="请假时学生是否在校"
            prop="openInSchool"
            label-width="150px"
        >
            <el-radio-group v-model="ruleForm.openInSchool">
                <el-radio :label="'1'">是</el-radio>
                <el-radio :label="'2'">否</el-radio>
            </el-radio-group>
        </el-form-item>
    </div>
</template>
<script>
import {CoworkingCampusOAVacationManagementModel} from "@/models/CoworkingCampusOAVacationManagement.js";
import {mapState} from "vuex";
const kitName = "kitsLeaveAbsence"
export default {
    name: "KitsLeaveAbsence",
    props: {
        reasonLeaveRequired: {
            type: Number,
            default: () => {
                return 0
            }
        },
        openInSchool: {
            type: String,
            default: "0"
        }
    },
    data() {
        return {
            // 特殊处理
            specialHandling: [
                {
                    schoolId: '311',
                    rules: {
                        value1: {
                            show: true,
                            labelName: '请假日期',

                        },
                        value2: {
                            show: false,
                            labelName: '',
                        }
                    }
                },
                {
                    schoolId: '222',
                    rules: {
                        value1: {
                            show: true,
                            labelName: '请假日期',

                        },
                        value2: {
                            show: false,
                            labelName: '',
                        }
                    }
                }
            ],
            normal: {
                rules: {
                    value1: {
                        show: true,
                        labelName: '开始时间',
                    },
                    value2: {
                        show: true,
                        labelName: '结束时间',
                    }
                },

            },
            form: {
                show1: false,
                label1: '开始时间',
                show2: false,
                label2: '结束时间',
            },
            ruleForm: {
                value1: "",
                value2: "",
            },
            rules: {},
            leaveList: [],
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
        transferIcon() {
            return require("./img/transfer-icon.png");
        },
        reasonLeaveRequiredType() {
            return this.reasonLeaveRequired === 0 ? false : true;
        },
        abstaractList() {
            const _this = this;
            let  specialSchools = this.specialHandling.map(item => {
                return item.schoolId;
            });
            let isSpecialSchool = specialSchools.includes(this.schoolId);
            let abstaractList = [
                {
                    label:'请假类型',
                    id:'leaveType',
                    required:true,
                    isBelongKit:true,
                    kitKey:kitName
                },
                {
                    label:isSpecialSchool ? '请假日期' :'开始时间',
                    id:'leaveStartTime',
                    required:true,
                    isBelongKit:true,
                    kitKey:kitName
                },
                {
                    label:'结束时间',
                    id:'leaveEndTime',
                    required:true,
                    isBelongKit:true,
                    kitKey:kitName
                },
                {
                    label:'请假事由',
                    id:'reasonLeave',
                    required:_this.reasonLeaveRequired === 0 ? false : true,
                    isBelongKit:true,
                    kitKey:kitName
                },
            ]

            if(isSpecialSchool){
                abstaractList = abstaractList.filter(item => item.id !== 'leaveEndTime')
            }


            return abstaractList;
        }
    },
    watch: {
        reasonLeaveRequiredType: {
            handler(n,o) {
                this.sendAbstract();
            },

        }
    },
    created() {
        this.init();
    },
    mounted() {
        this.sendAbstract();
    },
    methods: {
        init() {
            console.log(this.schoolId,'schoolId')
            this.initPageData();
        },
        initPageData() {
            this.setPageData();
            this.getList();
        },
        /**
         * @Description: 初始化页面参数
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-12-07 09:02:08
         */
        setPageData () {
            let  specialSchools = this.specialHandling.map(item => {
                return item.schoolId;
            });
            let contentItem,
                idx;

            if (specialSchools.includes(this.schoolId)) {

                idx = this.specialHandling.findIndex(item => {
                   return item.schoolId == this.schoolId
                });
                contentItem = this.specialHandling[idx];

                this.form.label1 = contentItem.rules.value1.labelName;
                this.form.label2 = contentItem.rules.value2.labelName;
                this.form.show1 = contentItem.rules.value1.show;
                this.form.show2 = contentItem.rules.value2.show;
            } else {
                this.form.label1 = this.normal.rules.value1.labelName;
                this.form.label2 = this.normal.rules.value2.labelName;
                this.form.show1 = this.normal.rules.value1.show;
                this.form.show2 = this.normal.rules.value2.show;
            }
        },
        /**
         * @Description: 获取请假类型列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-04 11:18:12
         */
        getList() {
            const coworkingCampusOAVacationManagementModel =
                new CoworkingCampusOAVacationManagementModel();
            coworkingCampusOAVacationManagementModel
                .getUndeletedList({schoolId: this.schoolId, deleteStatus: "0",status: "1",})
                .then((res) => {
                    if (res.data.code == "200") {
                        const data = res.data.data;
                        let ar1 = [];
                        data.forEach((item) => {
                            let obj = {
                                label: "",
                                value: "",
                            };

                            this.$set(obj, "label", item.vacationName);
                            this.$set(obj, "value", item.id);
                            ar1.push(obj);
                        });
                        this.leaveList = ar1;
                        console.log(this.leaveList, "data--data");
                    }
                });
        },
        sendAbstract() {
            this.$eventDispatch('handlerKitAbstract', this.abstaractList)
        }
    },
};
</script>
<style lang="scss" scoped>
.title {
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    align-items: center;
    .transfer-icon {
        width: 11px;
        height: 11px;
        margin-right: 6px;
        display: inline-block;
    }
    .label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2b2f33;
    }
}

.el-form-item {
    padding-right: 0 !important;
}
</style>
